// Imports
// import { groupBy } from "lodash-es";
import mitt from "mitt";
import Ractive from "ractive";

//################################
//# === Variable setup ==       ##
let stationNameList: any;
var stationNameCache = new Map();

//################################
//# === CONFIGRABLE SETTINGS == ##

// Update interval of page data, in seconds
const autoUpdateFreq = 35;
const minUpdateFreq = 10;
const timeWindow = 40;

// Radius for nearby stations
let nearbyRadius = 700;

// Ractive debugging
Ractive.DEBUG = false;

// ### TYPESCRIPT TODO ###
// - Optional property access operator
// - Optional element access operator
// - Optional call operator

//################################
//# === TYPES and INTERFACES=== ##
interface Deviation {
  Consequence: string;
  ImportanceLevel: number;
  Text: string;
}
interface StopInfo {
  GroupOfLine: string;
  StopAreaName: string;
  StopAreaNumber: string;
  TransportMode: string;
}
interface StopPointDeviation {
  Deviation: Deviation;
  StopInfo: StopInfo;
}
interface LineDeviation {
  Text: string;
  Consequence: string;
  ImportanceLevel: number;
}
interface TransportData {
  GroupOfLine: string;
  TransportMode: string;
  LineNumber: string;
  Destination: string;
  JourneyDirection: number;
  StopAreaName: string;
  StopAreaNumber: number;
  StopPointNumber: number;
  StopPointDesignation: string;
  TimeTabledDateTime: string;
  ExpectedDateTime: string;
  DisplayTime: string;
  JourneyNumber: number;
  Deviations: LineDeviation[];
}
interface ResponseData {
  LatestUpdate: string;
  DataAge: number;
  Metros: TransportData[];
  Buses: TransportData[];
  Trains: TransportData[];
  Trams: TransportData[];
  Ships: TransportData[];
  StopPointDeviations: StopPointDeviation[];
}
interface Response {
  StatusCode: number;
  Message: string;
  ExecutionTime: number;
  ResponseData: ResponseData;
}
interface MsgReceivedDepartures {
  result: Response;
  cache: boolean;
}

interface Stations {
  Name: string;
  SiteId: string;
  Type: string;
  X: string;
  Y: string;
}
interface StationsResponse {
  StatusCode: number;
  Message: string;
  ExecutionTime: number;
  ResponseData: Stations[];
}
interface MsgReceivedStations {
  result: StationsResponse;
}

interface MsgToWorker {
  url: string;
  channelName: string;
}

interface MsgError {
  statusCode: string;
  statusMsg: string;
  error: string;
}

interface MsgGeoResult {
  errorCode?: string;
  errorText?: string;
  stopLocationOrCoordLocation?: void;
}
interface MsgGeoReceived {
  result: MsgGeoResult;
}

interface MsgUpdate {
  force: boolean;
}

// mitt types, all possible "bus" names
type Events = {
  "worker.ajax": MsgToWorker;
  "worker.debug": object;
  "stationsinfo.result": MsgReceivedDepartures;
  "stationsinfo.error": MsgError;
  "update.data": MsgUpdate;
  "stationgeolist.result": MsgGeoReceived;
  "stationnamelist.result": MsgReceivedStations;
  "stationnamelist.error": MsgError;
  "stationname.result": MsgReceivedStations;
};

// All possible type of valid parameters in the URL
interface Parameters {
  siteId: string;
  similar: string;
  transport: string;
}

// Transport filter types
interface TransportFilter {
  [index: string]: boolean;
}

// For the Map of parameters in URLparameters class
type ParametersMap = Map<keyof Parameters, string>;

// History interfaces
interface HistoricState {
  state: ParametersMap;
}

//###########################
//# TRANSPORT FILTER

let transportTypes = ["BUS", "METRO", "TRAM", "TRAIN", "SHIP"];

let transportFilterShowAll: TransportFilter = {
  BUS: true,
  METRO: true,
  TRAM: true,
  TRAIN: true,
  SHIP: true,
};

//############################
//# == UTILITY FUNCTIONS == ##

// From: https://stackoverflow.com/questions/14446511/most-efficient-method-to-groupby-on-an-array-of-objects
const groupBy = <T>(array: T[], predicate: (value: T, index: number, array: T[]) => string) =>
  array.reduce((acc, value, index, array) => {
    (acc[predicate(value, index, array)] ||= []).push(value);
    return acc;
  }, {} as { [key: string]: T[] });

function minutesSinceNow(someDate: Date): number {
  return Math.round((someDate?.getTime() - Date.now()) / (1000 * 60));
}

// Parse latestUpdate format
function parseTrafikDatestamp(lu: string): Date {
  // "2019-01-26T12:43:37"
  if (lu !== undefined) {
    let [nDate, nTime] = lu.split("T");
    if (nDate !== undefined && nTime !== undefined) {
      let [nYear, nMonth, nDay] = nDate.split("-");
      let [nHours, nMinutes, nSeconds] = nTime.split(":");
      return new Date(Number(nYear), Number(nMonth) - 1, Number(nDay), Number(nHours), Number(nMinutes), Number(nSeconds));
    }
  }
  return new Date();
}

// SiteId can't be undefined or anything other than numbers (in a string)
function invalidSiteId(siteId: string | undefined): boolean {
  return typeof siteId === "undefined" || siteId === undefined || siteId === null || !siteId.match(/^[0-9]+$/);
}

function validLineDir(lineDir: string): boolean {
  let [n1, n2] = lineDir.split("-");
  return typeof n1 !== "undefined" && typeof n2 !== "undefined" && !isNaN(parseInt(n1, 10)) && !isNaN(parseInt(n2, 10));
}

// Parse URL for similar lines (and direction)
function parseSimilar(similarLines: string): Set<unknown> {
  if (similarLines == null || similarLines == "" || similarLines == "undefined") {
    return new Set();
  }
  let newSimilar = new Set();
  for (let similarLine of similarLines.split(",")) {
    if (validLineDir(similarLine)) {
      newSimilar.add(similarLine);
    }
  }
  return newSimilar;
}

function similarToString(similarSet: any): string {
  let setString = [];
  for (let s of similarSet) {
    setString.push(s);
  }
  return setString.join(",");
}

// Get element from ID
function gId(id: string): HTMLElement | null {
  return document.getElementById(id);
}

function getMaxOfArray(numArray: any): number {
  return Math.max.apply(null, numArray);
}

function debugtext(text: any): void {
  let now = Intl.DateTimeFormat("sv", { hour: "numeric", minute: "numeric", second: "numeric" }).format(new Date());
  return console.log("[DEBUG]", now, text);
}

// A sleeper...yawn.. Waits for "time" millisecs then the Promise will get resolved
// Example waitFor(5000).then( => console.log "Done waiting!")
function waitFor(millisecs: number): Promise<unknown> {
  return new Promise((resolve) => setTimeout(resolve, millisecs, "done!"));
}

function displayTimeToNumber(displayTime: string): string {
  // Undefined
  if (typeof displayTime === "undefined") {
    return "-1";
  }

  // DisplayTime is an actual time, like "20:42", convert that to minutes from now
  if (displayTime.match(/:/)) {
    let [hour, minute] = Array.from(displayTime.split(":"));
    // Assume that the departure time is TODAY
    let departureTime = new Date();
    let now = new Date();

    // Set the departure hour and minute to assumed TODAY
    departureTime.setHours(Number(hour));
    departureTime.setMinutes(Number(minute));

    // And, check if the departure time is before now, then it's TOMORROW and not today...
    if (departureTime.getTime() < now.getTime()) {
      // Add a day
      departureTime.setTime(departureTime.getTime() + 1000 * 3600 * 24);
    }
    // Return diff in minutes as a string
    return minutesSinceNow(departureTime).toString();
  }

  // Time is right now, "Nu"
  if (displayTime === "Nu") {
    return "0";
  }
  if (displayTime === "-") {
    return "-1";
  }

  // displayTime is in normal format, like "3 min", but check anyway
  if (displayTime.match(/min/)) {
    let [minutes = "?", _] = Array.from(displayTime.split(" "));
    return minutes;
  }

  // I guess that it's just a number, return it
  return displayTime;
}

// Change each departure entry DisplayTime into minutes until departure
function fixDisplayTime(departures: TransportData[]): TransportData[] {
  return departures.map((entry) => {
    entry.DisplayTime = displayTimeToNumber(entry.DisplayTime).toString();
    return entry;
  });
}

// Restart the hourglass animation
function restartAnimation(id: string) {
  let el = gId(id);
  // No element found
  if (!el) {
    return;
  }

  // Restart by doing a DOM reflow
  el.classList.remove("hourglass");
  el.offsetWidth;
  el.classList.add("hourglass");
}

// Get HTTP-parameters
function getQSParameterByName(name: string): string | undefined {
  let match = RegExp(`[?&]${name}=([^&]*)`).exec(window.location.search);
  if (match && match[1]) {
    return decodeURIComponent(match[1].replace(/\+/g, " "));
  }
  return undefined;
}

// Fetch stationname from siteid, using HTTP call
function getStationName(siteId: string): string {
  // In cache? Return it
  if (stationNameCache.has(siteId)) {
    return stationNameCache.get(siteId);
  }

  // Get station name from siteId, since no cookies are used to store any data
  if (siteId.match(/^[0-9]+$/)) {
    emitter.emit("worker.ajax", {
      url: `${masterURL}/search/api2/typeahead.json?searchstring=${siteId}&StationsOnly=True&maxresults=30`,
      channelName: "stationname",
    });
  }

  // Return placeholder text
  return "(Stationens namn hämtas)";
}

function clickedStationName() {
  siteId = undefined;
  urlParams.clearParams();
  // window.history.pushState(urlParams.getParamsMap(), "", "/");
  stationNameList.set("showSearch", true);
  stationsInfo.set("showDepartures", false);
  runUpdates = false;
  stationsInfo.set("similarLinesMultiple", new Set());
  stationsInfo.set("transportFilter", transportFilterShowAll);
  stationsInfo.set("transportFilterActive", false);

  // Set focus on input field
  let input = gId("stationNameInput");
  input?.focus();
  //input.setSelectionRange(0, input.value.length, "none");
}

function toggleSimilar(selectedLine: string) {
  // Get existing similar lines that was previous clicked
  let similarLinesMultiple = stationsInfo.get("similarLinesMultiple");
  if (similarLinesMultiple.has(selectedLine)) {
    // Same is selected, remove markings
    similarLinesMultiple.delete(selectedLine);
  } else {
    // Non-marked is selected, keep the linenumber and journey direction for "isSimilar()"
    similarLinesMultiple.add(selectedLine);
  }
  // Keep the values
  stationsInfo.set("similarLinesMultiple", similarLinesMultiple);
  if (similarLinesMultiple.size == 0) {
    urlParams.removeParam("similar");
  } else {
    urlParams.setParam("similar", similarToString(similarLinesMultiple));
  }

  // Set history and the URL
  window.history.pushState(urlParams.getParamsMap(), "", urlParams.show);
}

function makeTransportFilter(transport: string | undefined): TransportFilter {
  if (transport === undefined) {
    console.log("undefined transport type, showing all types");
    return transportFilterShowAll;
  }
  let newTransportfilter = new Object() as TransportFilter;
  transportTypes.forEach((tt) => (tt === transport ? (newTransportfilter[tt] = true) : (newTransportfilter[tt] = false)));
  return newTransportfilter;
}

// CLASS Keeper of parameters
class URLparameters {
  // Private keeper of the parameters
  #parameters: ParametersMap;

  constructor() {
    let presentURL = decodeURIComponent(window.location.search)
      .slice(1)
      .split("&")
      .map((e) => e.split("=") as [keyof Parameters, string]);
    // this.parameters = new Map();
    this.#parameters = new Map(presentURL);
  }

  get show() {
    let newURLparams: string = "";
    let first = true;
    this.#parameters.forEach((v, k) => {
      if (v !== undefined && k !== undefined) {
        if (first) {
          newURLparams = newURLparams.concat("?");
          first = false;
        } else {
          newURLparams = newURLparams.concat("&");
        }
        newURLparams = newURLparams.concat(`${k}=${v}`);
      }
    });
    // return encodeURIComponent(newURLparams);
    return newURLparams;
  }

  getParam(p: keyof Parameters): string | undefined {
    return this.#parameters.get(p);
  }

  setParam(p: keyof Parameters, v: string) {
    if (v === undefined || v === "") {
      return;
    }
    this.#parameters.set(p, v);
  }

  getParamsMap() {
    return this.#parameters;
  }

  setParamsMap(newMap: ParametersMap) {
    this.#parameters = newMap;
  }

  removeParam(p: keyof Parameters) {
    this.#parameters.delete(p);
  }

  clearParams() {
    this.#parameters.clear();
  }
}

// #################################
// # === START OF APPLICATION === ##

// Keeper of URL parameters
let urlParams = new URLparameters();

// Channel for Message Queues
let emitter = mitt<Events>();

// Debugging listener
// emitter.on("*", (c, msg) => {
//   console.log( "<PUBSUB>" );
//   console.log(c, msg);
//   console.log( "</PUBSUB>" );
// });

// Window focus, and run-updates, flags
var inFocus = true;
var runUpdates = false;

// Trafiklab proxy
var masterURL = "";

// Tracker of when page was last updated
let lastUpdate = new Date();

// #####################
// # == WEB WORKER == ##

function startWebWorker(): void {
  // Queue for outgoing Web Worker messages, before it's ready to receive them
  let queue: MsgToWorker[] = [];
  // Flag to indicate that the Web Worker is running
  let ajaxerReady = false;
  // Start it
  let ajaxer = new Worker("js/ajaxWorker.js");
  // Wait for messages from Web Worker
  ajaxer.onmessage = function (event): void {
    // Web Worker ready?
    if (event.data.ready != null) {
      // Yes, so flag it as ready
      ajaxerReady = true;
      // And de-queue the messages to send
      for (let msg of Array.from(queue)) {
        ajaxer.postMessage({
          url: msg.url,
          channelName: msg.channelName,
        });
      }
      // Clear queue
      queue = [];
    }

    // Depending on if it's a success or error, send to different channels
    switch (event.data.status) {
      case "success":
        return emitter.emit(event.data.channelName.concat(".result"), {
          result: event.data.result,
          cache: true,
        });
      case "error":
        return emitter.emit(event.data.channelName.concat(".error"), {
          statusCode: event.data.result.status,
          statusMsg: event.data.result.statusText,
          error: "BORK BORK",
        });
      case undefined:
        if (event.data.ready) {
          // Leftover message from web worker startup
          // console.log("WW ready");
        } else {
          console.log("Undefined message received", event);
        }
        return;
      default:
        return emitter.emit(event.data.channelName, event.data.msg);
    }
  };

  // Handle error messages from the worker
  ajaxer.onerror = function (event): never {
    console.log("BORK! BORK! ERROR IN WORKER!");
    throw new Error(event.message + " (" + event.filename + ":" + event.lineno + ")");
  };

  // Listen for jobs to send to Web Worker
  emitter.on("worker.ajax", (msg: MsgToWorker): void => {
    if (!ajaxerReady) {
      queue.push(msg);
      return;
    } else {
      return ajaxer.postMessage({
        url: msg.url,
        channelName: msg.channelName,
      });
    }
  });

  // Debug channel, for debug messages inside Web Worker
  emitter.on("worker.debug", (msg: object): void => {
    debugtext("<WORKER DEBUG>");
    debugtext(msg);
    return debugtext("</WORKER DEBUG>");
  });
}

// Subscription handler for station departure results, i.e. departures
emitter.on("stationsinfo.result", (msg: MsgReceivedDepartures): void => {
  // Clear eventual prevous error message
  stationsInfo.set("Error", false);

  // Indicate that loading is done
  stationsInfo.set("loading", false);
  restartAnimation("hourglass");

  // Check if any errors occurred, otherwise clear prevous error message
  if (msg.result.StatusCode !== 0) {
    stationsInfo.set("Error", {
      ErrorCode: msg.result.StatusCode,
      ErrorMessage: `Från SL: '${msg.result.Message}'`,
      ErrorLink: "http://status.trafiklab.se/2412460",
    });
    let updated = stationsInfo.get("updateTimestamp");
    stationsInfo.set("timeSinceLastUpdate", -minutesSinceNow(updated));
    return;
  } else {
    stationsInfo.set("Error", false);
  }

  // Update age of data
  let updated = parseTrafikDatestamp(msg.result.ResponseData.LatestUpdate);
  stationsInfo.set("updateTimestamp", updated);

  // Timestamp of since last updated the data
  stationsInfo.set("timeSinceLastUpdate", -minutesSinceNow(updated));

  // Handle StopPointDeviations, deviations that might be station wide
  if (msg.result.ResponseData.StopPointDeviations.length !== 0) {
    stationsInfo.set(
      "StopPointDeviationsGRPD",
      groupBy(msg.result.ResponseData.StopPointDeviations, function (spd) {
        return spd.StopInfo.StopAreaNumber;
      })
    );
  }

  // Merge all departures from all transport modes into one big pile (array)
  let departuresPile = msg.result.ResponseData.Buses.concat(msg.result.ResponseData.Metros)
    .concat(msg.result.ResponseData.Trains)
    .concat(msg.result.ResponseData.Trams)
    .concat(msg.result.ResponseData.Ships);
  // console.debug([...departuresPile, "", false, undefined].filter((d) => (d ? true : false)));
  // Filter out any falsey values, like undefined, empty etc
  let departures = fixDisplayTime(departuresPile.filter((d) => (d ? true : false)));

  // Sort by departure time, lowest departure time first (on top of webpage)
  let departuresSorted = departures.sort((a, b) => Number(a.DisplayTime) - Number(b.DisplayTime));

  // Put on webpage
  stationsInfo.set("departures", departuresSorted);

  // Get station name, since there is no extra data stored in any cookies and it's easy and quick
  const siteId = getQSParameterByName("siteId");
  if (siteId !== undefined) {
    stationsInfo.set("stationName", getStationName(siteId));
  }

  return;
});

// Subscription handler for errors in the station departures
emitter.on("stationsinfo.error", (msg: MsgError): void => {
  stationsInfo.set("loading", false);
  stationsInfo.set("Error", {
    ErrorCode: msg.statusCode,
    ErrorMessage: msg.statusMsg,
  });

  // Set the timeSinceLastUpdate based on previous update, in minutes
  let updated = stationsInfo.get("updateTimestamp");
  stationsInfo.set("timeSinceLastUpdate", -minutesSinceNow(updated));

  return;
});

// ##############################
// # == PAUSE MODAL HANDLER == ##

let pauseModal = new Ractive({
  el: "pauseContainer",
  template: "#pauseTemplate",
  data: {
    pauseActive: false,
  },
});

// ########################
// # == TITLE HANDLER == ##

let titleHandler = new Ractive({
  el: "titleContainer",
  template: "#titleTemplate",
  data: {
    title: "Stationsinformation",
  },
});

// #########################
// # == UPDATE HANDLER == ##

// Subscription handler for updating page data
emitter.on("update.data", (msg: MsgUpdate): void => {
  let now = Date.now();

  // Don't update unless forced or more than the minimum update frequency, to ease up on bandwith use
  if (msg.force || now - lastUpdate.getTime() > minUpdateFreq * 1000) {
    lastUpdate = new Date();
    // const updateSiteId = getQSParameterByName("siteId");
    const updateSiteId = urlParams.getParam("siteId");
    if (!invalidSiteId(updateSiteId)) {
      runUpdates = true;
      updatePage(updateSiteId);
    } else {
      // siteId have been made invalid in the URL, display and stop updating
      runUpdates = false;
      stationsInfo.set("Error", {
        ErrorCode: "0000",
        ErrorMessage: `Felaktigt SiteId: '${updateSiteId}'`,
        ErrorLink: "",
      });
    }
  }
});

// XXX Experiment
// function makeUP(siteId: string) {
//   if (!invalidSiteId(siteId)) {
//     let newUpdatePage = () => updatePage(siteId);
//     return newUpdatePage;
//   }
// }

function updatePage(siteId: string | undefined): void {
  // Check that it's not empty/undefined, and check that it's a number
  if (!invalidSiteId(siteId) && runUpdates) {
    // Show that data is loading
    stationsInfo.set("loading", true);

    // Initiate a AJAX request if siteId is found as a query string on the URL
    emitter.emit("worker.ajax", {
      url: `${masterURL}/realtid/api2/realtimedeparturesV4.json?siteid=${siteId}&timewindow=${timeWindow}`,
      channelName: "stationsinfo",
    });
  }
}

// ####################################
// # == Receivers of station data == ##

// Results from Trafiklab of stations nearby (GeoLocation)
emitter.on("stationgeolist.result", (msg: MsgGeoReceived): void => {
  // Received data so no longer loading
  stationNameList.set("loading", false);

  // Clear eventual inout search results
  stationNameList.set("Site", []);

  // Handle received errors
  if ("errorCode" in msg.result) {
    stationNameList.set("Error", {
      ErrorCode: msg.result.errorCode,
      ErrorMessage: msg.result.errorText,
    });
    // Skip the rest of page updating
    return;
  }

  // Clear error message if no errors occured
  stationNameList.set("Error", false);

  // Check if received data has locations
  if (msg.result.hasOwnProperty("stopLocationOrCoordLocation")) {
    // Result has locations, show them
    stationNameList.set("NoGeoSites", false);
  } else {
    // No stations found nearby
    stationNameList.set("NoGeoSites", true);

    // Increase the radius if none found, store it in the button
    let searchRadius = nearbyRadius;
    let nextSearchRadius = nearbyRadius;
    var geoButton = document.getElementById("geoButton");

    // Any previous radius stored on the geo-button?
    if (geoButton) {
      if ("searchRadius" in geoButton.dataset) {
        // Has radius stored, but is it a number?
        searchRadius = Number(geoButton.dataset["searchRadius"]);
        if (Number.isNaN(searchRadius)) {
          nextSearchRadius = nearbyRadius;
        } else {
          // ignore stored value and just double it
          nextSearchRadius = nearbyRadius * 2;
        }
        // Store new radius on button
        geoButton.dataset["searchRadius"] = nextSearchRadius.toString();

        // And update what the search radius was
        stationNameList.set("searchRadius", searchRadius);
      } else {
        // No radius stored, use default
        geoButton.dataset["searchRadius"] = nearbyRadius.toString();
      }
    } else {
      // When would this match, since we clicked the geobutton to even get here :-)
    }
  }

  // Show list of nearby stations
  stationNameList.set("GeoSites", msg.result.stopLocationOrCoordLocation);
  // console.log(msg.result.LocationList.StopLocation); //DEBUG
});

// Updates the webpage with the search results
emitter.on("stationnamelist.result", (msg: MsgReceivedStations): void => {
  // Not loading anymore
  stationNameList.set("loading", false);

  // Handle errors
  if (msg.result.StatusCode !== 0) {
    stationNameList.set("Error", {
      ErrorCode: msg.result.StatusCode,
      ErrorMessage: `Från SL: '${msg.result.Message}'`,
      ErrorLink: "http://status.trafiklab.se/1231464",
    });
    // Skip the rest of page updating
    return;
  }

  // Clear error message if no errors occured
  stationNameList.set("Error", false);

  // Put results on webpage
  stationNameList.set("Site", msg.result.ResponseData);

  // And store all the station names found in the cache, one of them is at least of use
  msg.result.ResponseData.forEach((nameHit) => stationNameCache.set(nameHit.SiteId, nameHit.Name));
});

// Function that gets called in the "error" subscription
emitter.on("stationnamelist.error", (msg: MsgError): void => {
  stationNameList.set("loading", false);
  stationNameList.set("Error", {
    ErrorCode: msg.statusCode,
    ErrorMessage: msg.statusMsg,
  });
});

// Set the stationname on webpage
emitter.on("stationname.result", (msg: MsgReceivedStations): void => {
  if (msg.result.ResponseData.length > 0) {
    stationsInfo.set("stationName", msg.result.ResponseData[0]?.Name);
    titleHandler.set("title", "Station: " + msg.result.ResponseData[0]?.Name);
    // Set cache with siteId -> StationName for next update
    stationNameCache.set(msg.result.ResponseData[0]?.SiteId, msg.result.ResponseData[0]?.Name);
  }
});

// ########################################
// ## ==  FIND STATION ID (STAGE ONE) == ##

// // Get siteId from query string
// let siteId = getQSParameterByName("siteId"); // XXX REMOVE?
// if (!invalidSiteId(siteId)) {
//   urlParams.setParam("siteId", <string>siteId);
// }

// Make a Ractive object that will handle the search for a station
stationNameList = new Ractive({
  el: "listStationsContainer",
  template: "#listStationsTemplate",
  data: {
    searchRadius: nearbyRadius,
    NoGeoSites: false,
    showSearch: true,

    // Remove invalid SiteIds, like "0"
    excludeSiteIdZero(sites: Stations[]) {
      return sites.filter((site) => site.SiteId !== "0");
    },

    // Partial match of station names
    filterSites(sites: any[]) {
      let searchRegExp = new RegExp(`.*${stationNameList.get("stationNameInput")}.*`, "ig");
      sites.filter((siteEntry: { Name: string }) => siteEntry.Name.match(searchRegExp));
    },

    // Clicked on a station in the list of stations
    clickedNewStation(clickedSiteId: string) {
      //stationNameList.set('Site', []);
      // If event triggered double, the clickedSiteId will be undefined.
      if (invalidSiteId(clickedSiteId)) {
        return;
      }
      // siteId = clickedSiteId; // XXX Needed?
      urlParams.setParam("siteId", clickedSiteId);

      // Set history and the URL
      window.history.pushState(urlParams.getParamsMap(), "", urlParams.show);
      stationsInfo.set("showDepartures", true);
      //this.set("showSearch", false);
      stationNameList.set("showSearch", false);
      runUpdates = true;
      updatePage(clickedSiteId);
    },

    geoLocation() {
      // User chose to list stations nearby, go and find them if possible!
      // Possible to get GPS-position?
      if ("geolocation" in navigator) {
        // Geolocation is available
        navigator.geolocation.getCurrentPosition(function (position) {
          stationNameList.set("loading", true);
          let searchRadius = nearbyRadius;
          var geoButton = document.getElementById("geoButton");
          if (geoButton) {
            if ("searchRadius" in geoButton.dataset) {
              // console.log("has radius");
              searchRadius = Number(geoButton.dataset["searchRadius"]);
            } else {
              // console.log("no radius");
              geoButton.dataset["searchRadius"] = nearbyRadius.toString();
            }
            // console.log(searchRadius);

            // emitter.emit("worker.ajax", {
            //   url: `${masterURL}/geo/api2/nearbystops.json?originCoordLat=59.4&originCoordLong=17.5&radius=${searchRadius}`,
            //   channelName: "stationgeolist"
            // });
            //
            // console.log(
            //   `${masterURL}/geo/api2/nearbystopsv2.json?originCoordLat=${position.coords.latitude}&originCoordLong=${position.coords.longitude}&radius=${searchRadius}`
            // );

            // Get any nearby stations to location
            emitter.emit("worker.ajax", {
              url: `${masterURL}/geo/api2/nearbystopsv2.json?originCoordLat=${position.coords.latitude}&originCoordLong=${position.coords.longitude}&r=${searchRadius}&maxNo=16&type=S`,
              channelName: "stationgeolist",
            });
          }
        });
      }
    },

    // User clicked list of stations found nearby (GeoLocation)
    clickedGeoStation(mainMastExtId: string): void {
      // Nothing in the mainMastExtId to extrace the SiteID from
      if (mainMastExtId === undefined) {
        console.log("Here again");
        return;
      }

      // "mainMastExtId": "300109192" contains SiteID for Slussen, the last 4 digits
      var stationId = mainMastExtId.slice(-4);

      // Check that it's a valid SiteID
      if (invalidSiteId(stationId)) {
        return;
      }

      // It's a valid SiteID
      // siteId = stationId; /// XXX remove global, and use the urlParams instead ALL over the codebase
      urlParams.setParam("siteId", stationId);

      window.history.pushState(urlParams.getParamsMap(), "", urlParams.show);
      stationsInfo.set("showDepartures", true);
      //this.set("showSearch", false);
      stationNameList.set("showSearch", false);
      runUpdates = true;
      updatePage(stationId);
    },
  },
});

stationNameList.observe("stationNameInput", function (stationNameInput: string) {
  if (typeof stationNameInput !== "undefined" && stationNameInput !== null && !(stationNameInput.length <= 2)) {
    stationNameList.set("loading", true);
    stationNameList.set("stationNameInput", stationNameInput);
    emitter.emit("worker.ajax", {
      url: `${masterURL}/search/api2/typeahead.json?searchstring=${stationNameInput}&StationsOnly=True&maxresults=30`,
      channelName: "stationnamelist",
    });
  }
});

// ###############################################
// ## == SHOW STATION DEPARTURES (STAGE TWO) == ##

// Make a Ractive object that will handle the station information
var stationsInfo = new Ractive({
  el: "stationsInfoContainer",
  template: "#stationsInfoTemplate",
  data: {
    showDepartures: false,
    similarLinesMultiple: new Set(),
    departures: [],
    timeSinceLastUpdate: "?",
    transportModeIMG(departure: TransportData) {
      // Mostly busses and "pendeltåg"
      switch (departure.TransportMode) {
        case "BUS":
          return "BUS.svg";
          break;
        case "TRAIN":
          return "TRAIN.svg";
          break;
        case "SHIP":
          return "SHIP.svg";
          break;
      }
      // More named modes of transport
      switch (departure.GroupOfLine.toLowerCase()) {
        case "tunnelbanans gröna linje":
          return "METRO_green.svg";
          break;
        case "tunnelbanans röda linje":
          return "METRO_red.svg";
          break;
        case "tunnelbanans blå linje":
          return "METRO_blue.svg";
          break;
        case "tvärbanan":
          return "LRAIL.svg";
          break;
        case "nockebybanan":
          return "LRAIL.svg";
          break;
        case "lidingöbanan":
          return "LRAIL.svg";
          break;
        case "roslagsbanan":
          return "LRAIL.svg";
          break;
        case "saltsjöbanan":
          return "LRAIL.svg";
          break;
        case "spårväg city":
          return "TRAM.svg";
          break;
        case "spårväg city linje":
          return "TRAM.svg";
          break;
      }
      // Debugging
      //console.log(departure);
      return debugtext("*** NO MATCH FOR TRANSPORT MODE");
    },

    // Change appearence of departure times that are soon (CSS)
    departureLook(displayTime: number) {
      if (displayTime <= 5) {
        return "soon";
      } else {
        return "future";
      }
    },

    // Will output "Nu" instead of "0 min"
    departureText(displayTime: string): string {
      if (displayTime === "0") {
        return "Nu";
      } else {
        return `${displayTime} min`;
      }
    },

    // Shows an icon if departure have any deviations
    hasDeviations(departure: TransportData): string {
      let maxDeviationImportanceLevel: number, maxStopPointDeviationImportanceLevel: number;

      if (
        (departure.Deviations === undefined || departure.Deviations === null || departure.Deviations.length === 0) &&
        stationsInfo.get("StopPointDeviationsGRPD") === undefined
      ) {
        return "";
      }

      // CHECK STATION SPD
      if (
        stationsInfo.get("StopPointDeviationsGRPD") === undefined ||
        stationsInfo.get("StopPointDeviationsGRPD")[departure.StopAreaNumber] === undefined
      ) {
        maxStopPointDeviationImportanceLevel = -1;
      } else {
        let SPDa = stationsInfo.get("StopPointDeviationsGRPD")[departure.StopAreaNumber];
        maxStopPointDeviationImportanceLevel = getMaxOfArray(SPDa);
      }

      if (departure.Deviations === undefined || departure.Deviations === null || departure.Deviations.length === 0) {
        maxDeviationImportanceLevel = -1;
      } else {
        let deviationImportanceLevels = departure.Deviations.map((deviation) => deviation.ImportanceLevel);
        // Get the max level, if any (otherwise set it to -1)
        maxDeviationImportanceLevel = Math.max(...deviationImportanceLevels, -1);
      }

      // No deviations for this departure
      if (maxDeviationImportanceLevel === -1 && maxStopPointDeviationImportanceLevel === -1) {
        return "";
      }
      // If it's ImportanceLevel above 5 on any deviation, use warning sign
      if (maxDeviationImportanceLevel > 5 || maxStopPointDeviationImportanceLevel > 5) {
        return "<img src='img/warning.svg' class='deviation-img'>";
      } else {
        // Otherwise just a info sign
        return "<img src='img/Information_icon.svg' class='deviation-img'>";
      }
    },

    // The output of one deviation
    parseDeviation(deviation: Deviation): string {
      if (deviation?.ImportanceLevel > 5) {
        return `<div class='severity-high'>&#8226; ${deviation.Text}</div>`;
      } else {
        return `<div class='severity-low'>&#8226; ${deviation.Text}</div>`;
      }
    },

    // Stop Point deviations, match the StopAreaNumber grouped information
    stopPointDeviations(stopAreaNumber: string): string[] | undefined {
      if (
        stopAreaNumber === undefined ||
        stationsInfo.get("StopPointDeviationsGRPD") === undefined ||
        stationsInfo.get("StopPointDeviationsGRPD")[stopAreaNumber] === undefined
      ) {
        return undefined;
      }

      // Return a different css class depending on the deverity
      let devs = Array.from<StopPointDeviation>(stationsInfo.get("StopPointDeviationsGRPD")[stopAreaNumber]).map(
        (spd: StopPointDeviation) => {
          return spd.Deviation.ImportanceLevel > 5
            ? `<div class='severity-high'>&#8226; ${spd.Deviation.Text}</div>`
            : `<div class='severity-low'>&#8226; ${spd.Deviation.Text}</div>`;
        }
      );

      // return a deduplicated array of deviations, using sets
      return Array.from(new Set(devs));
    },

    upperCase(text: string): string {
      return text.toUpperCase();
    },

    notZero(minutes: number): boolean {
      return minutes !== 0;
    },

    // How delayed is the departure from timetable, indicate graphics
    runningLate(departure: { ExpectedDateTime: string; TimeTabledDateTime: string }) {
      //return unless departure.ExpectedDateTime and departure.TimeTabledDateTime
      let lateIndicator: string, severity: string;
      if (!departure.ExpectedDateTime || !departure.TimeTabledDateTime) {
        return {
          minutes: 0,
          severityLevel: "",
          graphics: "",
        };
      }

      let minutesLate = Math.round(
        (parseTrafikDatestamp(departure.ExpectedDateTime).getTime() - parseTrafikDatestamp(departure.TimeTabledDateTime).getTime()) /
          1000 /
          60
      );

      //return if minutesLate is < 1
      if (minutesLate < 1) {
        return {
          minutes: 0,
          severityLevel: "",
          graphics: "",
        };
      }

      switch (false) {
        case !(minutesLate <= 5):
          lateIndicator = "1-5";
          severity = "littleLate";
          break;
        case !(minutesLate <= 10):
          lateIndicator = "5-10";
          severity = "late";
          break;
        default:
          lateIndicator = "10+";
          severity = "veryLate";
      }

      return {
        minutes: minutesLate,
        severityLevel: severity,
        graphics: lateIndicator,
      };
    },

    transportModes: [
      {
        TransportMode: "BUS",
        Translation: "Buss",
      },
      {
        TransportMode: "METRO",
        Translation: "Tunnelbana",
      },
      {
        TransportMode: "TRAM",
        Translation: "Lokalbana",
      },
      {
        TransportMode: "TRAIN",
        Translation: "Pendeltåg",
      },
      {
        TransportMode: "SHIP",
        Translation: "Färja",
      },
    ],

    transportFilterActive: false,

    transportFilter: {
      BUS: true,
      METRO: true,
      TRAM: true,
      TRAIN: true,
      SHIP: true,
    },

    filterDepartures(departures: TransportData[]): TransportData[] {
      //let showTransportMode = this.get("transportFilter");
      let showTransportMode = stationsInfo.get("transportFilter");
      return departures.filter((departure) => showTransportMode[departure.TransportMode]);
    },

    // Is it the same direction and line number, select it also
    isSimilar(LineNumber: string, JourneyDirection: string) {
      // Checks if the line number and journey direction matches stored value, see "toggleSimilar()"
      let line = LineNumber + "-" + JourneyDirection;
      //let similarLinesMultiple = this.get("similarLinesMultiple");
      let similarLinesMultiple = stationsInfo.get("similarLinesMultiple");
      // Old
      // if (similarLinesMultiple.has(line)) {
      //   return "similarLine";
      // }
      // NEW
      if (similarLinesMultiple.has(line)) {
        // Return the similar line class plus the index match of the set, max 4 different classes
        return "similarLine-" + ([...similarLinesMultiple].indexOf(line) % 5);
      }
      return "";
    },
  },
});

// Reoccurring updates, it calls itself (instead of an infinite while(true) {} loop)
function timedUpdate(): any {
  return waitFor(autoUpdateFreq * 1000).then(function () {
    // Only update data if window are in focus
    if (inFocus && runUpdates) {
      emitter.emit("update.data", {
        force: false,
      });
    }
    return timedUpdate();
  });
}

// #############################
// # == CLICK/TOUCH EVENTS == ##

// Tapping or clicking on a departure will show/hide it's deviations
["click", "touchend"].forEach((newEvent) => {
  gId("stationsInfoContainer")?.addEventListener(newEvent, function (event: Event) {
    let t = <HTMLElement>event.target;

    // Only show the deviations if any of the SVG-images is clicked/touched
    if (t.matches("img.deviation-img")) {
      // Prevent event from bubbling up
      event.preventDefault();
      while (t && t !== event.currentTarget) {
        // Looking for the main (parent) row for just this departure
        if (t.matches("section.departure")) {
          // Show deviations
          t.querySelector(".deviations")?.classList.toggle("show");
          break;
        }
        // Not found, check parent
        t = <HTMLElement>t.parentElement;
      }
    }

    // Click/touch on the transport mode, only show just that transport mode (or show all, like a toggle)
    if (t.matches("div.transportMode") || t.matches("img.transportMode")) {
      // Prevent event from bubbling up
      event.preventDefault();

      // Check if undefined or not present in known transport types ("transportFilterShowAll")
      if (t.dataset["transportmode"] !== undefined && t.dataset["transportmode"] in transportFilterShowAll) {
        if (stationsInfo.get("transportFilterActive") === true) {
          // Clearing filter
          stationsInfo.set("transportFilter", transportFilterShowAll);
          stationsInfo.set("transportFilterActive", false);
          urlParams.removeParam("transport");
        } else {
          // Setting a filter so only the chosen transport type is shown
          let newTf = new Object() as TransportFilter;
          transportTypes.forEach((tt) => (tt === t.dataset["transportmode"] ? (newTf[tt] = true) : (newTf[tt] = false)));
          stationsInfo.set("transportFilter", newTf);
          stationsInfo.set("transportFilterActive", true);
          urlParams.setParam("transport", t.dataset["transportmode"]);
        }
        window.history.pushState(urlParams.getParamsMap(), "", urlParams.show);
      }
    }

    // A click/touch on a linenumber
    if (t.matches("div.lineNumber")) {
      // Prevent event from bubbling up
      event.preventDefault();
      // Check that it's a match if a line and journey direction (format "43-2")
      if ("similar" in t.dataset && t.dataset["similar"]?.match("[0-9A-Z]+-[0-9]+")) {
        // Toggle all the matching lines
        toggleSimilar(t.dataset["similar"]);
      }
    }

    // Clicked the stationname, go back to search input
    if (t.matches("#stationName")) {
      // Prevent event from bubbling up
      event.preventDefault();
      clickedStationName();
    }
  });
});

// If window is out of focus, skip updating the data
let pause = () => {
  //console.log("PAUSE");
  inFocus = false;
  pauseModal.set("pauseActive", true);
};
window.addEventListener("blur", pause);

// When window gets focus (tabbed in clicked inside), update the data
let unpause = () => {
  //console.log("UNPAUSE");
  inFocus = true;
  pauseModal.set("pauseActive", false);
  emitter.emit("update.data", {
    force: false,
  });
};
window.addEventListener("focus", unpause);

// Back and forward in webpage "history"
window.onpopstate = (event: HistoricState) => {
  if (event.state !== null) {
    if (event.state === undefined || "get" in event.state === false || event.state.size === 0) {
      // Back to the very start, which should not happen, anyhow show search box
      clickedStationName();
    } else {
      // Change of state to previous state
      stationNameList.set("showSearch", false);
      stationsInfo.set("showDepartures", true);
      runUpdates = true;
      // Set the params to previous state
      urlParams.setParamsMap(event.state);
      if (urlParams.getParam("similar")) {
        stationsInfo.set("similarLinesMultiple", parseSimilar(urlParams.getParam("similar") || ""));
      } else {
        // Clear the similars since it's not in the previous state
        stationsInfo.set("similarLinesMultiple", new Set());
      }
      if (urlParams.getParam("transport")) {
        stationsInfo.set("transportFilter", makeTransportFilter(urlParams.getParam("transport")));
        stationsInfo.set("transportFilterActive", true);
      } else {
        // Clear the transport filter since it's not in the previous state
        stationsInfo.set("transportFilter", transportFilterShowAll);
        stationsInfo.set("transportFilterActive", false);
      }

      // Update the page with the previous siteId
      updatePage(event.state.get("siteId"));
    }
  }
};

// #############################
// # == STARTING IT ALL UP == ##

// Start Web Worker, needed for all JSON fetching
startWebWorker();

// Start the update loop, crude for now (better to have a message being sent)
timedUpdate();

// Get siteId from query string
let siteId = getQSParameterByName("siteId"); // XXX REMOVE?
if (!invalidSiteId(siteId)) {
  urlParams.setParam("siteId", <string>siteId);
}

// If siteId exists start loop
if (!invalidSiteId(siteId)) {
  runUpdates = true;
  stationNameList.set("showSearch", false);
  stationsInfo.set("showDepartures", true);

  stationsInfo.set("similarLinesMultiple", parseSimilar(getQSParameterByName("similar") || ""));
  urlParams.setParam("similar", <string>getQSParameterByName("similar") || "");

  urlParams.setParam("siteId", <string>siteId);
  urlParams.setParam("transport", getQSParameterByName("transport") || "");
  if (urlParams.getParam("transport")) {
    stationsInfo.set("transportFilter", makeTransportFilter(urlParams.getParam("transport")));
    stationsInfo.set("transportFilterActive", true);
  }

  // And make a initial update to the page, XXX Why get it from QS?
  // updatePage(getQSParameterByName("siteId"));
  updatePage(urlParams.getParam("siteId"));
}
